export const listEndpoints = {
  0: "active-stores",
  1: "active-clients",
  2: "new-stores",
  3: "closed-stores",
  4: "mortality-rate-active-stores",
  5: "mortality-rate-new-stores",
  6: "nfe-exits-monthly-by-cnae",
  7: "nfe-exits-12m-by-cnae",
  8: "nfe-avg-exits-monthly-by-cnae",
  9: "nfe-avg-exits-12m-by-cnae",
  10: "active-stores-per-city-zip",
};

export const listEndpointsNcm = {
  0: "nfe-entries-monthly-by-ncm",
  1: "nfe-entries-12m-by-ncm",
  2: "nfe-exits-monthly-by-ncm",
  3: "nfe-exits-12m-by-ncm",
};

export const listEndpointsRanking = [
  "stores-growth-12m-limited",
  "stores-per-client-growth-12m-limited",
  "cnae-outputs-growth-12m-limited",
  "cnae-inputs-growth-12m-limited",
  "ncm-outputs-growth-12m-limited",
  "ncm-inputs-growth-12m-limited",
  "ncm-export-growth-12m-limited",
  "ncm-import-growth-12m-limited",
];
