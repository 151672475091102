import React, { useState } from "react";
import Select, { components } from "react-select";
import { useMedia } from "react-use";

import "./Option.css";

function Option() {
  const [selectedOptions, setSelectedOptions] = useState();
  const isWide = useMedia("(min-width: 130em)");

  const msgStyles = {
    color: "var(--gray)",
    fontSize: "14px",
  };
  function handleSelect(data) {
    setSelectedOptions(data);
  }

  let optionList = [{ value: "BR", label: "Brasil" }];
  const fetchPost = async () => {
    try {
      const response = await fetch(
        "https://servicodados.ibge.gov.br/api/v1/localidades/estados"
      );
      const data = await response.json();
      data.forEach((estado) => {
        optionList.push({ value: estado.sigla, label: estado.nome });
      });

      return optionList;
    } catch (error) {
      console.log(error);
    }
  };
  fetchPost();

  const styles = {
    noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      fontSize: isWide ? "22px" : "14px",
      fontFamily: "Roboto",
    }),
    menu: (base) => ({
      ...base,
      fontSize: isWide ? "22px" : "14px",
      fontFamily: "Roboto",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "30px",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "var(--primary)",
      fontWeight: "500",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#dbe9f5",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "var(--primary)",
      ":hover": {
        backgroundColor: "var(--primary)",
        color: "white",
      },
    }),
    singleValue: (styles) => ({
      ...styles,
      color: "var(--gray)",
    }),
  };

  const NoOptionsMessage = (props) => {
    return (
      <components.NoOptionsMessage {...props}>
        <span>Nenhuma opção encontrada</span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <div className="option-1">
      <div className="localidade-1">
        <Select
          className="buscarPlaceholder"
          options={optionList}
          value={selectedOptions}
          onChange={handleSelect}
          styles={styles}
          components={{ NoOptionsMessage }}
          isSearchable
          defaultValue={optionList[0]}
        />
      </div>
    </div>
  );
}

export default Option;
