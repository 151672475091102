import React from "react";
import MenuBurger from "../MenuBurger";
import Navigation from "../../Navigation";
import Footer from "../../Footer";

function GeneralContainer(props) {
  return (
    <div className="container-general">
      <MenuBurger />
      <Navigation />
      {props.children}
      <Footer />
    </div>
  );
}

export default GeneralContainer;
