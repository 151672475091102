import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import axios from "../services/axios";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../store/auth-context";
import Button from "../components/UI/Button";
import Spinner from "../components/UI/Spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema, yupIsLogin } from "../utils/schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./AuthForm.css";

function AuthForm({ login, forgot }) {
  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const isLogin = login;
  const [isForgot, setIsForgot] = useState(forgot);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordRequired, setPasswordRequired] = useState(true);
  const [forgotPasswordConfirm, setForgotPasswordConfirm] = useState();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (isLogin) yupIsLogin("isLogin");
    else if (!isLogin) yupIsLogin("isRegister");
  }, [isLogin]);

  if (forgotPassword) yupIsLogin("changePassword");
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, touchedFields, isValid },
    watch,
    setError,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const [passwordValidity, setPasswordValidity] = useState({
    minLenght: null,
    upperCase: null,
    minNumbers: null,
    minSymbols: null,
  });

  const inputPassword = getValues().password?.toString();
  useEffect(() => {
    const isUpperCase = /(?=.*[A-Z])/;
    const isNumberRegex = /(?=.*[0-9])/;
    const isSymbol = /(?=.*[.!@()#$%&'*+/=?^_`{|}~-])/;

    watch();
    setPasswordValidity({
      minLenght: inputPassword?.length >= 8,
      upperCase: isUpperCase.test(inputPassword),
      minNumbers: isNumberRegex.test(inputPassword),
      minSymbols: isSymbol.test(inputPassword),
    });
  }, [inputPassword, watch, errors]);

  const passwordValidityList = {
    minLenght: {
      isValid: passwordValidity?.minLenght,
      text: "Mínimo de 8 caracteres",
    },
    upperCase: {
      isValid: passwordValidity?.upperCase,
      text: "Mínimo 1 letra maiúscula",
    },
    minNumbers: {
      isValid: passwordValidity?.minNumbers,
      text: "Mínimo 1 número",
    },
    minSymbols: {
      isValid: passwordValidity?.minSymbols,
      text: "Mínimo 1 símbolo",
    },
  };

  const PasswordStrength = ({ isValid, text }) => {
    return (
      <li className={`strength-validation ${isValid ? "green" : "gray"}`}>
        <p>{isValid ? "✅ " : "☑️ "}</p>
        <p>{text}</p>
      </li>
    );
  };

  var passwordStrength = Object.keys(passwordValidityList).map(function (key) {
    return (
      <PasswordStrength
        key={key}
        isValid={passwordValidityList[key].isValid}
        text={passwordValidityList[key].text}
      />
    );
  });

  // const switchAuthModeHandler = () => {
  //   history.push(!isLogin ? "/login" : "/register");
  //   setIsLogin((prevState) => !prevState);
  // };

  const submitHandle = async (userData) => {
    setLoading(true);
    const url = isLogin ? "/v1/auth/login" : "/v1/auth/register";
    const user = isLogin
      ? { username: userData.email, password: userData.password }
      : {
          email: userData.email,
          senha: userData.password,
          confirmar_senha: userData.confirmPassword,
        };

    try {
      setLoading(true);
      const response = await axios.post(url, user);

      if (isLogin) {
        const id = response.data.id_token;
        const accId = response.data.access_token;
        const expires = response.data.expires_in.toString();
        authCtx.login(id, accId, expires);
        setLoading(false);
        history.replace("/ranking");
      } else {
        setEmail(response.data.code_delivery_stats.destination);
        setEmail(email);
        setLoading(false);
        setConfirmation(true);
      }
    } catch (error) {
      setLoading(false);
      let errorMessage = `Falha no ${isLogin ? "login" : "cadastro"}!`;
      setError("apiError", { message: errorMessage });
    }
  };

  const switchForgotHandler = async () => {
    setIsForgot((prevState) => !prevState);
    await yupIsLogin("isForgot");
    history.push("/forgot-password");
  };

  const handleForgotPassword = async () => {
    try {
      setPasswordRequired((prevState) => !prevState);

      const email = getValues().email?.toString();
      setEmail(email);
      const userName = { username: email };
      axios.post("/v1/auth/forgotten", userName);
      await yupIsLogin("changePassword");
      setIsForgot((prevState) => !prevState);
      setForgotPassword(true);
    } catch (error) {
      setError("email", { message: error });
      console.log(error);
    }
  };

  useEffect(() => {
    if (isForgot) yupIsLogin("isForgot");
  }, [isForgot, passwordRequired]);

  const submitChangePassword = async () => {
    try {
      const userPass = {
        code: getValues().code?.toString(),
        user: email,
        new_password: getValues().password?.toString(),
      };
      axios.post("/v1/auth/change-password", userPass);
      setConfirmation(true);
      setForgotPasswordConfirm(true);
    } catch (error) {
      console.log(error);
    }
  };

  const confirmationMsg = (
    <p className="success-register">
      {forgotPasswordConfirm
        ? "Troca de senha realizada com sucesso. Faça o"
        : `Cadastro realizado com sucesso. Favor acessar o email ${email} e confirmar a conta. Após, faça o`}

      <Link className="success-register-link" to="/login">
        login.
      </Link>
    </p>
  );

  return (
    <>
      {!loading && !confirmation && (
        <section className="form-div">
          {!confirmation && !forgotPassword && !isForgot && !loading && (
            <h1 className="title-auth">{isLogin ? "Login" : "Criar Conta"}</h1>
          )}
          {(forgotPassword || isForgot) && (
            <>
              <h1 className="title-auth">
                {!isForgot ? "Troca de Senha" : "Esqueceu a senha?"}
              </h1>

              {!forgotPasswordConfirm && forgotPassword && (
                <p className="msg-change-password">
                  Você recebeu um e-mail com um código para a troca da sua senha
                </p>
              )}
            </>
          )}
          {errors.apiError && (
            <p className="msg-error">{errors.apiError?.message}</p>
          )}
          {confirmation && !forgotPassword && (
            <h1 className="title-auth">
              {register && "Conta Criada com Sucesso!"}
            </h1>
          )}
          <form className="form">
            {!forgotPassword && (
              <div className="input-div">
                <label className="label" htmlFor={"email"}>
                  <p>E-mail</p>
                </label>
                <div className={`input-div-2 `}>
                  <input
                    className={"input-register"}
                    type={"text"}
                    name="email"
                    autoComplete="username"
                    {...register("email", {
                      required: true,
                      message: "Campo email obrigatório",
                    })}
                  />
                </div>
                <span className="error-message">
                  {touchedFields.email && errors.email?.message}
                </span>
              </div>
            )}
            {forgotPassword && (
              <div className="input-div">
                <label className="label" htmlFor={"code"}>
                  <p>Código</p>
                </label>
                <div className={"input-div-2"}>
                  <input
                    className="input-register"
                    type={"number"}
                    name="code"
                    {...register("code")}
                  />
                </div>
                <span className="error-message">{errors.code?.message}</span>
              </div>
            )}
            {!isForgot && (
              <>
                <div className="input-div">
                  <label className="label" htmlFor={"password"}>
                    <p>{forgotPassword && "Nova "}Senha</p>
                  </label>
                  <div className={"input-div-2"}>
                    <input
                      className="input-register"
                      type={passwordShown ? "text" : "password"}
                      autoComplete={"current-password"}
                      name="password"
                      {...register("password")}
                    />
                    <div className="icon">
                      <FontAwesomeIcon
                        icon={
                          passwordShown ? ["fas", "eye"] : ["fas", "eye-slash"]
                        }
                        onClick={togglePasswordVisiblity}
                      />
                    </div>
                  </div>
                  <span className="error-message">
                    {errors.password?.message && "Campo senha é obrigatório"}
                  </span>
                </div>
                <div className="password-strength">{passwordStrength}</div>
              </>
            )}
            {!isLogin && !isForgot && (
              <div className="input-div">
                <label className="label" htmlFor={"confirmPassword"}>
                  <p>Confirmar {forgotPassword && "Nova "} Senha</p>
                </label>
                <div className={"input-div-2"}>
                  <input
                    className="input-register"
                    type={"password"}
                    autoComplete={"current-password"}
                    name="confirmPassword"
                    {...register("confirmPassword", { required: true })}
                  />
                </div>
                <span className="error-message">
                  {touchedFields.confirmPassword &&
                    errors.confirmPassword?.message}
                </span>
              </div>
            )}

            {isForgot || forgotPassword ? (
              <Button
                disabled={!isValid}
                class={"button"}
                onSubmit={
                  forgotPassword ? submitChangePassword : handleForgotPassword
                }
              >
                {forgotPassword ? "Trocar senha" : "Solicitar código"}
              </Button>
            ) : (
              <Button
                disabled={!isValid}
                class={"button"}
                onSubmit={handleSubmit(submitHandle)}
              >
                {isLogin ? "Login" : "Criar conta"}
              </Button>
            )}
            {isLogin && (
              <div className="forgot-password-button">
                <Button class={"link"} hover onSubmit={switchForgotHandler}>
                  Esqueci minha senha
                </Button>
              </div>
            )}
          </form>
          {/* <Button class={"link"} onSubmit={switchAuthModeHandler} hover>
            {isLogin ? "Criar uma conta" : "Já tem uma conta? Faça Login"}
          </Button> */}
        </section>
      )}
      {confirmation && confirmationMsg}
      {loading && <Spinner />}
    </>
  );
}
export default withRouter(AuthForm);
