import React, { useState, useEffect } from "react";
import Filter from "../../Filter";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMedia } from "react-use";

function Busca(props) {
  const [isMediaQuery, setIsMediaQuery] = useState({
    small: false,
    large: false,
  });

  const [fontSize, setFontSize] = useState({ busca: "14px", menu: "14px" });

  const [width, setWidth] = useState(window.innerWidth);

  const isWide = useMedia("(min-width: 130em)");

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    if (width < 430) {
      setIsMediaQuery({ small: true, large: false });
      setFontSize({ busca: "10px", menu: "10px" });
    }

    if (width > 2080) {
      setIsMediaQuery({ small: false, large: true });
      setFontSize({ busca: "22px", menu: "18px" });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  const msgStyles = {
    color: "var(--gray)",
    fontSize: fontSize.menu,
  };

  const noOpsMsg = () => {
    return (
      <div className="noOptionsMessage">
        <FontAwesomeIcon
          style={{ width: "20px" }}
          className="icon"
          icon={["fas", "triangle-exclamation"]}
        />
        <span>Nenhum resultado encontrado</span>
      </div>
    );
  };

  const styles = {
    noOptionsMessage: (base) => ({ ...base, ...msgStyles }),
    control: (base) => ({
      ...base,
      border: 0,
      boxShadow: "none",
      fontSize: isWide ? "22px" : "14px",
      fontFamily: "Roboto",
    }),
    menu: (base) => ({
      ...base,
      fontSize: isWide ? "22px" : "14px",
      fontFamily: "Roboto",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "auto",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "var(--primary)",
      fontWeight: "500",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#dbe9f5",
      fontSize: isWide ? "22px" : "14px",
      maxWidth: isMediaQuery.small ? "250px" : "100%",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "var(--primary)",

      ":hover": {
        backgroundColor: "var(--primary)",
        color: "white",
      },
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      padding: isMediaQuery.small && "4px",
    }),
    clearIndicator: (styles) => ({
      ...styles,
      padding: isMediaQuery.small && "4px",
    }),
    valueContainer: (styles) => ({
      ...styles,
      padding: isMediaQuery.small && "4px",
      fontSize: isMediaQuery.small && "10px",
      color: "var(--gray)",
    }),
    input: (styles) => ({
      ...styles,
      // fontSize: isMediaQuery && "1px",
    }),
    placeholder: (styles) => ({
      ...styles,
      color: "var(--gray)",
    }),
  };

  return (
    <div className="buscar">
      <div className="busca">
        <div className="buscar-1">
          <div className="search-icon">
            <FontAwesomeIcon
              className="icon"
              icon={["fas", "magnifying-glass"]}
            />
          </div>
          <AsyncPaginate
            defaultValue={
              props.searchFromRanking
                ? {
                    value: props.searchFromRanking?.value,
                    label: props.searchFromRanking?.label,
                  }
                : null
            }
            styles={styles}
            className="buscarPlaceholder"
            placeholder={`Escolha uma ${
              props.pathnameEstab
                ? "atividade econômica"
                : "categoria de mercadoria"
            }`}
            loadOptions={props.loadOptions}
            onChange={props.handleSelect}
            onBlur={props.handleBlur}
            isSearchable={true}
            isMulti
            additional={{
              page: 1,
            }}
            noOptionsMessage={noOpsMsg}
          />
        </div>
      </div>
      {props.filter && <Filter handleDatePick={props.handleDatePick} />}
    </div>
  );
}

export default Busca;
