export const disclaimerEstab = [
  "Quantidade de estabelecimentos ativos. Um estabelecimento é definido pela combinação entre CNPJ BÁSICO (B), CNPJ ORDEM (O) e CNPJ DV (DV): BB.BBB.BBB/OOOO-DV. Cada CNPJ ORDEM equivale a um estabelecimento: 0001, 0002, etc. (não necessariamente em ordem)",
  "Quantidade de clientes ativos.Cada CNPJ BÁSICO equivale a um cliente. Por exemplo, a Rede Extra tem CNPJ BÁSICO 10.864.769 e tem vários estabelecimentos.",
  "Quantidade de estabelecimentos abertos nos últimos 12 meses.",
  "Quantidade de estabelecimentos fechados nos últimos 12 meses.",
  "Quantidade de estabelecimentos fechados nos últimos 12 meses dividido pela quantidade de estabelecimentos ativos.",
  "Quantidade de estabelecimentos fechados nos últimos 12 meses dividido pela quantidade de estabelecimentos ativos.",
  "Total financeiro mensal de emissões de Notas Fiscais Eletrônicas para aquele CNAE.",
  "Total financeiro 12 meses de emissões de Notas Fiscais Eletrônicas para aquele CNAE.",
  "Total financeiro mensal de emissões de Notas Fiscais Eletrônicas para aquele CNAE dividido pelo total de estabelecimentos ativos.",
  "Total financeiro 12 meses de emissões de Notas Fiscais Eletrônicas para aquele CNAE dividido pelo total de estabelecimentos ativos.",
];

export const disclaimerRanking = [
  "Variação da quantidade de estabelecimentos por CNAE em 12 meses, limitado para CNAEs com mais de 1000 estabelecimentos.",
  "Variação do valor médio de estabelecimentos por CNPJ Básico por CNAE, limitado para CNAEs com mais de 1000 estabelecimentos.",
  "Variação do valor total 12 meses de saídas (vendas) por CNAE, limitado a montantes maiores que R$ 100 mm.",
  "Variação do valor total 12 meses de entradas (compras) por CNAE, limitado a montantes maiores que R$ 100 mm.",
  "Variação do valor total 12 meses de saídas (vendas) por NCM, limitado a montantes maiores que R$ 100 mm.",
  "Variação do valor total 12 meses de entradas (compras) por NCM, limitado a montantes maiores que R$ 100 mm.",
  "Variação do valor total 12 meses de exportações por NCM, limitado a montantes maiores que R$ 10 mm.",
  "Variação do valor total 12 meses de importações por NCM, limitado a montantes maiores que R$ 10 mm. ",
];

export const disclaimerProdutos = [
  "Total financeiro mensal de entradas de Notas Fiscais Eletrônicas para aquele NCM.",
  "Total financeiro 12 meses de entradas de Notas Fiscais Eletrônicas para aquele NCM.",
  "VariTotal financeiro mensal de emissões de Notas Fiscais Eletrônicas para aquele NCM.ação do valor total 12 meses de saídas (vendas) por CNAE, limitado a montantes maiores que R$ 100 mm.",
  "Total financeiro 12 meses de emissões de Notas Fiscais Eletrônicas para aquele NCM.",
];
