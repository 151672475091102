import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/logo-white.png";
import Video from "../../assets/Projeto.mp4";
import { AnimationOnScroll } from "react-animation-on-scroll";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import Iphone from "../../assets/iphone4.png";
import Perspective from "../../assets/perspective.svg";
import "./LandingPage.css";

function LandingPage() {
  const [focus, setFocus] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  return (
    <div className="lading-page">
      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
        <Navbar collapseOnSelect expand="lg" variant="dark">
          <Container>
            <Navbar.Brand href="/">
              <img className="navbar-brand-img" src={Logo} alt="Data Bakery" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ml-auto">
                <Nav.Link href="/login" className="ml-auto">
                  ENTRAR
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <section id="cover">
          <div className="container">
            <div className="row">
              <div id="cover-text" className="col-lg-6 col-12">
                <h1 className="text-white" style={{ marginBottom: "20px" }}>
                  Dados em que você pode confiar
                </h1>
                <p className="big" style={{ marginBottom: "40px" }}>
                  para tomar as suas decisões mais importantes
                </p>
                <a className="btn btn-primary" href="/contact">
                  EXPERIMENTE
                </a>
              </div>
              <div className="col-lg-6 col-12">
                <div className="video-teaser-overlay-hr"></div>
                <div className="video-teaser-overlay-vr"></div>
                <video
                  id="video-teaser"
                  autoPlay
                  loop
                  muted
                  playsInline
                  poster=""
                  preload="auto"
                >
                  <source src={Video} type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
        </section>
        <section id="features">
          <AnimationOnScroll
            animateOnce={true}
            animateIn="animate__fadeInUp"
            // delay={1000}
          >
            <div className="container center">
              <div className="container-background title-custom">
                <span className="badge-title badge-info">Ready to use</span>
                <h2>Plataforma de dados mais completa do mercado</h2>
                <p className="text-big">
                  Oferecemos inteligência de dados para que gestores de
                  investimentos, boutiques de M&A, consultorias e outras
                  empresas possam tomar decisões mais assertivas e estratégicas.
                </p>
              </div>
            </div>
          </AnimationOnScroll>
        </section>
        <section id="countUp">
          <div className="container countUp">
            <AnimationOnScroll
              animateOnce={true}
              delay={100}
              animateIn="animate__fadeIn"
            >
              <svg
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
                className="countUp-item"
              >
                <path
                  fill="var(--primary-light-2)"
                  d="M47,-53C59,-46,65.4,-29.3,64.2,-14.5C63,0.3,54.2,13.3,46,25.6C37.7,37.9,30,49.6,17.8,57.9C5.6,66.3,-11.1,71.2,-26.5,67.8C-41.9,64.3,-56.1,52.5,-65.9,37.2C-75.7,22,-81,3.4,-78.3,-14.1C-75.6,-31.6,-64.7,-47.9,-50.3,-54.4C-35.9,-61,-18,-57.7,-0.2,-57.5C17.5,-57.2,35.1,-60,47,-53Z"
                  transform="translate(100 100)"
                />
              </svg>
              <div className="countUp-cnaes">
                <CountUp
                  start={focus ? 0 : null}
                  end={1343}
                  duration={3}
                  separator="."
                  redraw={true}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setFocus(true);
                        }
                      }}
                    >
                      <span
                        ref={countUpRef}
                        style={{ color: focus ? "var(--gray-10)" : "" }}
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>

                <p>Atividades Econômicas</p>
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeIn"
              duration={2}
              delay={500}
            >
              <svg
                viewBox="0 0 200 200"
                xmlns="http://www.w3.org/2000/svg"
                className="countUp-item-2"
              >
                <path
                  fill="var(--primary-light-2)"
                  d="M41,-48.9C55.7,-36.5,71.8,-26,74,-13.1C76.2,-0.2,64.5,15.1,54.2,29.9C44,44.7,35.3,59,22,66.5C8.6,74,-9.4,74.7,-22.9,67.7C-36.3,60.7,-45.2,46,-55.6,30.8C-66,15.6,-77.9,-0.1,-79.5,-17.9C-81,-35.7,-72.2,-55.6,-57.2,-67.9C-42.2,-80.1,-21.1,-84.7,-4,-80C13.2,-75.3,26.3,-61.2,41,-48.9Z"
                  transform="translate(100 100)"
                />
              </svg>
              <div className="countUp-cnaes countUp-cnaes-2">
                <CountUp
                  start={isMobile ? 0 : null}
                  end={273582}
                  duration={3}
                  separator="."
                  redraw={true}
                >
                  {({ countUpRef }) => (
                    <VisibilitySensor
                      onChange={(isVisible) => {
                        if (isVisible) {
                          setIsMobile(true);
                        }
                      }}
                    >
                      <span
                        ref={countUpRef}
                        style={{ color: isMobile ? "var(--gray-10)" : "" }}
                      />
                    </VisibilitySensor>
                  )}
                </CountUp>

                <p>Categorias de Mercadorias</p>
              </div>
            </AnimationOnScroll>
          </div>
        </section>
        <section id="tryit">
          <div className="container">
            <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn">
              <div className="try-it-prototype">
                <div>
                  <h2>Garanta sua vantagem competitiva</h2>
                  <a className="btn btn-primary" href="/contact">
                    Experimente e surpreenda-se
                  </a>
                </div>
                <img
                  src={Perspective}
                  alt="data bakery prototype"
                  width={350}
                />
                {/* <img
                  src={Perspective2}
                  alt="try it"
                  style={{ width: "100%", height: "20%" }}
                /> */}
              </div>
            </AnimationOnScroll>
          </div>
        </section>

        <section id="iphone">
          <div className="container">
            <AnimationOnScroll
              animateOnce={true}
              animateIn="animate__fadeInRight"
              duration={"1.5"}
            >
              <div className="iphone-div title-custom">
                <img
                  src={Iphone}
                  alt="dispositivos - notebook, ipad e iphone"
                  width={"60%"}
                  className="gadgets-img"
                />
                <h2>Disponível para acesso em tablets e smartphones</h2>
              </div>
            </AnimationOnScroll>
          </div>
        </section>
      </AnimationOnScroll>
      <section id="footer">
        <div className="container">
          <div className="flex">
            <div>
              <a className="navbar-brand" href="https://databakery.com.br">
                <img src={Logo} height="40" alt="Data Bakery" />
              </a>
            </div>

            <div>
              <p className="roboto-mono small">Company</p>
              <ul
                className="list-unstyled roboto-mono small"
                style={{ textTransform: "uppercase" }}
              >
                <li>
                  <a className="text-white" href="/">
                    About Us
                  </a>
                </li>
              </ul>
            </div>

            <div className="div-contact">
              <p className="roboto-mono small">Contact</p>
              <ul
                className="list-unstyled roboto-mono small"
                style={{ textTransform: "uppercase" }}
              >
                <li>
                  <a
                    href="malito:contato@databakery.com.br"
                    className="text-white"
                  >
                    contato@databakery.com.br
                  </a>
                </li>
                <li>
                  <div>
                    <a
                      className="text-white"
                      href="https://www.linkedin.com/company/data-bakerybr/"
                    >
                      <FontAwesomeIcon
                        className="icon"
                        icon={["fab", "linkedin"]}
                      />
                    </a>
                    <a className="text-white" href="/">
                      <FontAwesomeIcon
                        className="icon"
                        icon={["fab", "instagram"]}
                      />
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex-center">
            <div>
              <p className="copyright">
                Copyright © 2023 | Data Bakery. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default LandingPage;
