import React from "react";

function Title(props) {
  const dataAtual = new Date(Date.now()).toLocaleString();
  return (
    <div className="estabelecimentos-1">
      <div className="estabelecimentos-2">
        <div className="empresas"></div>
        <h1 className="title-main x28px-bold">{props.title}</h1>
      </div>
      {props.atualizacao && (
        <div className="atualizacao">
          <div className="atualizado-em-29092022-1037 x14px">{`Atualizado em ${dataAtual}`}</div>
        </div>
      )}
    </div>
  );
}

export default Title;
