import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import AuthContext from "../../../store/auth-context";
import { slide as Menu } from "react-burger-menu";
import Dropdown from "react-bootstrap/Dropdown";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../MenuBurger/MenuBurger.css";

function MenuBurger(props) {
  const [height, setHeight] = useState();
  const [isOpen, setOpen] = useState(false);
  const [selected, setSelected] = useState();

  const [modalTopOffset, setModalTopOffset] = useState();
  const [modalLeftOffset, setModalLeftOffset] = useState();

  useEffect(() => {
    if (window.location.pathname === "/estabelecimentos") setSelected("estab");
    else if (window.location.pathname === "/produtos") setSelected("prod");
    else if (window.location.pathname === "/ranking") setSelected("ranking");
    else if (window.location.pathname === "/user") setSelected(null);
  }, []);

  const handleIsOpen = () => {
    setOpen(true);
    const movieElement = document.querySelector(".user");
    setModalTopOffset(
      movieElement?.getBoundingClientRect().top + window.scrollY + 50
    );
    setModalLeftOffset(movieElement?.getBoundingClientRect().left - 10);
  };

  const closeMenu = (select) => {
    setSelected(select);
    setOpen(false);
  };
  useEffect(() => {
    setHeight(window.innerHeight * 0.6);
  }, []);

  const history = useHistory();
  const authCtx = useContext(AuthContext);

  const handleClick = (eventkey) => {
    if (eventkey === "2" && authCtx.isLoggedIn) {
      authCtx.logout();
      history.replace("/");
    }

    if (eventkey === "1") {
      setSelected(null);
      closeMenu("prod");
      history.replace("/user");
    }
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="user-active"
    >
      {children}
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, className, "aria-labelledby": labeledBy }, ref) => {
      const value = "";

      return (
        <div
          ref={ref}
          // style={style}
          style={{
            bottom: `${modalTopOffset}px`,
            top: "auto",
            left: `${modalLeftOffset}px`,
          }}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <Menu right isOpen={isOpen} onOpen={handleIsOpen} onClose={closeMenu}>
      <NavLink
        to="/ranking"
        className={`${selected === "ranking" ? "selected1" : ""}`}
        onClick={() => closeMenu("ranking")}
      >
        Ranking
      </NavLink>
      <NavLink
        to="/estabelecimentos"
        className={`${selected === "estab" ? "selected1" : ""}`}
        onClick={() => closeMenu("estab")}
      >
        Estabelecimentos
      </NavLink>
      <NavLink
        to="/produtos"
        className={`${selected === "prod" ? "selected1" : ""}`}
        onClick={() => closeMenu("prod")}
      >
        Produtos
      </NavLink>
      <Dropdown onSelect={handleClick}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <div className="user" style={{ marginTop: height }}>
            {!props.selected === "user" ? (
              <FontAwesomeIcon className={"iconUser"} icon={["far", "user"]} />
            ) : (
              <FontAwesomeIcon className={"iconUser"} icon="fa-solid fa-user" />
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu}>
          <Dropdown.Item eventKey="1">Área do Usuário</Dropdown.Item>
          <Dropdown.Item eventKey="2">Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  );
}

export default MenuBurger;
