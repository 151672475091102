import React from "react";
import Loading from "../../../assets/loading.gif";

import "./Spinner.css";

const spinner = (props) => (
  <>
    <div className="spinner">
      <img src={Loading} alt="spinner" />
    </div>
  </>
);

export default spinner;
