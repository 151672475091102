import React, { useState, useCallback } from "react";
import Cookies from "js-cookie";

let logoutTimer;

const AuthContext = React.createContext({
  accToken: "",
  idToken: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
});

const calculateRemainingTime = (expirationTime) => {
  const currentTime = new Date().getTime();

  const adjExpirationTime = new Date(parseInt(expirationTime)).getTime();

  const remainingDuration = currentTime - adjExpirationTime;

  return remainingDuration;
};

const retrieveStoredToken = () => {
  const storedIdToken = Cookies.get("idToken");
  const storedAccToken = Cookies.get("accToken");
  const storedExpirationDate = Cookies.get("expirationTime");
  const remainingTime = calculateRemainingTime(storedExpirationDate);

  if (remainingTime <= 3600) {
    Cookies.remove("idToken");
    Cookies.remove("accToken");
    Cookies.remove("expirationTime");
    return null;
  }

  return {
    idToken: storedIdToken,
    accToken: storedAccToken,
    duration: remainingTime,
  };
};

export const AuthContextProvider = (props) => {
  const tokenData = retrieveStoredToken();

  let initialToken;
  let initialAccToken;
  if (tokenData) {
    initialToken = tokenData.idToken;
    initialAccToken = tokenData.accToken;
  }

  const [idToken, setIdToken] = useState(initialToken);
  const [accToken, setAccToken] = useState(initialAccToken);

  const userIsLoggedIn = !!idToken;

  const logoutHandler = () => {
    setIdToken(null);
    setAccToken(null);
    Cookies.remove("idToken");
    Cookies.remove("accToken");
    Cookies.remove("expirationTime");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };

  const loginHandler = (idToken, accToken, expirationTime) => {
    setIdToken(idToken);
    setAccToken(accToken);
    Cookies.set("idToken", idToken);
    Cookies.set("accToken", accToken);
    Cookies.set("expirationTime", expirationTime);
    const remainingTime = calculateRemainingTime(expirationTime);
    //TODO
    // logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  const contextValue = {
    idToken: idToken,
    accToken: accToken,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
