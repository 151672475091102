import GeneralContainer from "../../components/UI/GeneralContainer";
import Title from "../../components/UI/Title";

import "./User.css";

function User() {
  return (
    <GeneralContainer>
      <section className="main-user">
        <div className="main-scroll">
          <Title title="Perfil do Usuário" />
        </div>
      </section>
    </GeneralContainer>
  );
}
export default User;
