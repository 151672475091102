import * as yup from "yup";

export let schema = yup
  .object()
  .shape({
    email: yup
      .string()
      .email("Digite um e-mail válido")
      .required("Campo email é obrigatório"),
    password: yup
      .string()
      .required("Campo senha é obrigatório")
      .min(8, "")
      .matches(/(?=.*[A-Z])/, "")
      .matches(/(?=.*[0-9])/, "")
      .matches(/(?=.*[.!#$%&'*+/=?^_`{|}~-])/, ""),
    confirmPassword: yup
      .string()
      .required("Campo obrigatório")
      .oneOf([yup.ref("password")], "senha nao coincide"),
  })
  .required();

export const yupIsLogin = (status) => {
  if (status === "isForgot") {
    return (schema = yup
      .object()
      .shape({
        email: yup
          .string()
          .email("Digite um e-mail válido")
          .required("Campo email é obrigatório"),
      })
      .required());
  } else if (status === "isRegister") {
    return schema;
  } else if (status === "isLogin") {
    return (schema = yup
      .object()
      .shape({
        email: yup
          .string()
          .email("Digite um e-mail válido")
          .required("Campo email é obrigatório"),
        password: yup
          .string()
          .required("Campo senha é obrigatório")
          .min(8, "")
          .matches(/(?=.*[A-Z])/, "")
          .matches(/(?=.*[0-9])/, "")
          .matches(/(?=.*[.!#$%&'*+/=?^_`{|}~-])/, ""),
      })
      .required());
  } else if (status === "changePassword") {
    return (schema = yup
      .object()
      .shape({
        code: yup
          .number()
          .typeError("Insira um código válido")
          .integer("Favor inserir um número inteiro")
          .required("Campo código é obrigatório"),
        password: yup
          .string()
          .required("Campo senha é obrigatório")
          .min(8, "")
          .matches(/(?=.*[A-Z])/, "")
          .matches(/(?=.*[0-9])/, "")
          .matches(/(?=.*[.!#$%&'*+/=?^_`{|}~-])/, ""),
        confirmPassword: yup
          .string()
          .required("Campo obrigatório")
          .oneOf([yup.ref("password")], "senha nao coincide"),
      })

      .required());
  } else if (status === "contactus") {
    return (schema = yup
      .object()
      .shape({
        name: yup
          .string("Favor inserir um campo válido")
          .required("Campo nome é obrigatório"),
        lastName: yup
          .string("Favor inserir um campo válido")
          .required("Campo sobrenome é obrigatório"),
        email: yup
          .string()
          .email("Digite um e-mail válido")
          .required("Campo email é obrigatório"),
        message: yup
          .string("Favor inserir um campo válido")
          .required("Campo mensagem é obrigatório"),
      })

      .required());
  }
};
