import React from "react";
import "./Button.css";

function Button(props) {
  return (
    <div className={`button-form ${props.hover ? "button-form-hover" : ""}`}>
      <button
        type={props.type ? props.type : "button"}
        disabled={props.disabled}
        className={props.class}
        onClick={props.onSubmit}
      >
        {props.children}
      </button>
    </div>
  );
}

export default Button;
