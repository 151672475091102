import React from "react";

import "./Footer.css";

function Footer() {
  return (
    <footer className="footer-main x12px">
      <p>Todos os direitos reservados DATA BAKERY &copy; 2023</p>
    </footer>
  );
}

export default Footer;
