import React from "react";
import { DateRangePicker } from "rsuite";
import "./DateFilter.css";

function DateFilter(props) {
  const { after } = DateRangePicker;
  return (
    <DateRangePicker
      appearance="default"
      placeholder="Selecionar período"
      disabledDate={after(new Date("2022-12"))}
      format="dd-MM-yyyy"
      character={" - "}
      hoverRange="month"
      cleanable
      onChange={props.handleDatePick}
      placement="bottomEnd"
      style={{ height: "100%" }}
      defaultValue={[new Date("2022-12-01")]}
      showOneCalendar
      ranges={[]}
    />
  );
}

export default DateFilter;
