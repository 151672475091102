import styled from "styled-components";

import "./Modal.css";

const ModalCustome = styled.div`
  top: ${(props) => props.positionTop + 30}px;
  left: ${(props) => props.positionLeft + 30}px;
  @media (min-width: 130em) {
    top: ${(props) => props.positionTop - 30}px;
    left: ${(props) => props.positionLeft + 280}px;
  }
  @media (max-width: 30em) {
    left: ${(props) => props.positionLeft - 10}px;
  }
  @media (max-width: 36em) {
    left: ${(props) => props.positionLeft - 80}px;
  }
`;

function Modal(props) {
  return props.show ? (
    <ModalCustome
      positionTop={props.positionTop}
      positionLeft={props.positionLeft}
      className={`modal-custome ${
        props.ranking ? "modal-custome-ranking" : "modal-custome-show"
      }`}
    >
      {props.children}
      {!props.ranking && (
        <button onClick={props.close} className={props.closeClassName}>
          Fechar
        </button>
      )}
    </ModalCustome>
  ) : null;
}

export default Modal;
