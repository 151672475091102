import React, { useState, useEffect, useCallback } from "react";
import Moment from "moment";
import "./Chart.css";
import {
  BarChart,
  Bar,
  // Cell,
  LabelList,
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useHistory } from "react-router-dom";
import { useMemo } from "react";
import { useMedia } from "react-use";

function Chart(props) {
  const isMobile = useMedia("(max-width: 70em)");
  const dateToStr = (date) => {
    return `${date.getFullYear()}-${Moment(date).format("MM")}`;
  };

  const chartProps = props;
  const history = useHistory();

  // const [aux, setAux] = useState(false);
  // const [interval, setInterval] = useState(243);

  function useDeviceSize() {
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(200);

    const handleWindowResize = () => {
      if (chartProps.style) {
        setWidth(window.innerWidth * 0.9);
        setHeight(window.innerHeight * 0.9);
      }
    };

    useEffect(() => {
      handleWindowResize();
      window.addEventListener("resize", handleWindowResize);
      return () => window.removeEventListener("resize", handleWindowResize);
    }, []);

    return [width, height];
  }

  const [width, height] = useDeviceSize();

  const data = useMemo(() => {
    return [];
  }, []);

  const correctedMonth = useMemo(() => {
    return {
      "-01": "Jan-",
      "-02": "Fev-",
      "-03": "Mar-",
      "-04": "Abr-",
      "-05": "Mai-",
      "-06": "Jun-",
      "-07": "Jul-",
      "-08": "Ago-",
      "-09": "Set-",
      "-10": "Out-",
      "-11": "Nov-",
      "-12": "Dez-",
    };
  }, []);

  const formatarData = useCallback(
    (chart, chartInfos) => {
      let anoFormatado;
      let mesFormatado;
      if (typeof chartInfos[chart].DATA === "string") {
        anoFormatado = chartInfos[chart].DATA.substring(2, 4);
        mesFormatado = chartInfos[chart].DATA.substring(4, 7);
      } else {
        anoFormatado = chartInfos[chart].DATA.toString().substring(2, 4);
        mesFormatado = `-${chartInfos[chart].DATA.toString().substring(4, 7)}`;
      }

      return correctedMonth[mesFormatado] + anoFormatado;
    },
    [correctedMonth]
  );

  useEffect(() => {
    if (props.datePick && props.datePick.length > 0) {
      const firstDate = props.datePick[0];
      const secondDate = props.datePick[1];

      for (const chart in chartProps.chartInfos) {
        if (
          chartProps.chartInfos[chart].DATA >= dateToStr(firstDate) &&
          chartProps.chartInfos[chart].DATA <= dateToStr(secondDate)
        ) {
          data.push({
            name: formatarData(chart, chartProps.chartInfos),
            [chartProps.title]: chartProps.chartInfos[chart].BRASIL,
          });
        }
      }
    } else {
      for (const chart in chartProps.chartInfos) {
        if (props.ranking) {
          data.push({
            name: chartProps.chartInfos[chart].ID,
            value: chartProps.chartInfos[chart].CRESC,
            desc: chartProps.chartInfos[chart].DESC,
          });
        } else {
          data.push({
            name: formatarData(chart, chartProps.chartInfos),
            [chartProps.title]: chartProps.chartInfos[chart].BRASIL,
          });
        }
      }
    }
  }, [
    props.datePick,
    props.chartInfos,
    props.ranking,
    props.title,
    props.style,
    props.chartInfos,
    chartProps.chartInfos,
    chartProps.title,
    data,
    formatarData,
  ]);

  const args = {
    height: height,
    width: width,
    margin: { top: 5, right: 50, left: 25, bottom: 30 },
    line1Color: "var(--primary)",
    line1Key: props.title,
    data: data,
    align: "center",
    verticalAlign: "bottom",
    AnimaComponentShowCartesianGrid: true,
    AnimaComponentShowXAxis: true,
    AnimaComponentShowYAxis: true,
    AnimaComponentShowTooltip: true,
    AnimaComponentShowLegend: true,
  };
  const formatter = (value) => `${value}%`;

  const tickFormatterFunc = (num) => {
    if (props.ranking) {
      return num;
    } else {
      if (Math.abs(num) < 1000) return Math.sign(num) * Math.abs(num);
      else if (Math.abs(num) >= 1000000)
        return Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1) + "MM";
      else if (Math.abs(num) < 1000000)
        return Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K";
    }
  };

  const handleClick = (data, index) => {
    const url = props.title.includes("NCM") ? "/produtos" : "/estabelecimentos";
    history.push({
      pathname: url,
      state: {
        value: `${data.name}`,
        label: `${data.name} - ${data.desc}`,
      },
    });
  };

  return (
    <div className="chart-1">
      {props.datePick && data.length <= 0 ? (
        <div className="no-data-msg">
          Nenhum dado disponível para o período selecionado
        </div>
      ) : (
        <div className={`chart-2 ${props.ranking ? "ranking" : ""}`}>
          <ResponsiveContainer>
            {props.ranking ? (
              <BarChart {...args} data={data} layout="vertical">
                <XAxis
                  interval={0}
                  tickCount={10}
                  type="number"
                  dataKey="value"
                  label={{
                    value: "CRESCIMENTO",
                    position: "insideBottom",
                    offset: -10,
                    fontSize: "var(--font-size-s)",
                    fontFamily: "var(--font-family-roboto)",
                  }}
                  tickFormatter={(num) => {
                    return tickFormatterFunc(num);
                  }}
                />
                <YAxis
                  type="category"
                  dataKey="name"
                  label={{
                    value: props.title.includes("NCM") ? "NCMS" : "CNAES",
                    position: "insideLeft",
                    offset: isMobile ? -15 : -5,
                    angle: 270,
                    fontSize: "var(--font-size-s)",
                    fontFamily: "var(--font-family-roboto)",
                  }}
                  position="left"
                />

                <Tooltip
                  formatter={(value, name, props) => {
                    return [`${props.payload.desc}`];
                  }}
                  rapperStyle={{
                    outlineColor: "var(--primary)",
                  }}
                  contentStyle={{
                    whiteSpace: "pre-wrap",
                    maxWidth: "400px",
                    textAlign: "justify",
                    outline: "none",
                  }}
                  cursor={false}
                />

                <Bar
                  dataKey={"value"}
                  fill={args.line1Color}
                  barSize={35}
                  onClick={handleClick}
                >
                  <LabelList
                    dataKey={"value"}
                    position={"right"}
                    style={{
                      fill: "black",
                      fontSize: "var(--font-size-s)",
                      fontFamily: "var(--font-family-roboto)",
                    }}
                    formatter={formatter}
                  />
                  {/* {args.data.map((entry, index) => {
                  return (
                    <Cell
                      cursor="pointer"
                      fill={
                        index === activeIndex ? "var(--gray)" : "var(--primary)"
                      }
                      key={`cell-${index}`}
                    />
                  );
                })} */}
                </Bar>
              </BarChart>
            ) : (
              <LineChart {...args}>
                {args.AnimaComponentShowCartesianGrid && (
                  <CartesianGrid strokeDasharray="3 3" />
                )}
                {args.AnimaComponentShowXAxis && <XAxis dataKey="name" />}
                {args.AnimaComponentShowYAxis && (
                  <YAxis
                    fontSize={"14px"}
                    tickFormatter={(num) => {
                      return tickFormatterFunc(num);
                    }}
                  />
                )}
                {args.AnimaComponentShowTooltip && (
                  <Tooltip
                    wrapperStyle={{
                      outlineColor: "var(--primary)",
                    }}
                    formatter={(num) => {
                      return tickFormatterFunc(num);
                    }}
                  />
                )}
                <Line
                  type="monotone"
                  dataKey={args.line1Key}
                  stroke={args.line1Color}
                  dot={false}
                  activeDot
                />
              </LineChart>
            )}
          </ResponsiveContainer>
        </div>
      )}
    </div>
  );
}

export default Chart;
