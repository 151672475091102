import React from "react";
import ReactDOM from "react-dom";
import "./globals.css";
import "./styleguide.css";
import "./icons/fontAwsome";
import App from "./App";
import { AuthContextProvider } from "./store/auth-context";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <AuthContextProvider>
    <BrowserRouter>
      {/* <React.StrictMode> */}
      <App />
      {/* </React.StrictMode> */}
    </BrowserRouter>
  </AuthContextProvider>,
  document.getElementById("app")
);
