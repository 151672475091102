import React, { useState } from "react";
import DateFilter from "../DateFilter";
import FunnelIcon from "@rsuite/icons/Funnel";
import Option from "../Option";
import "./Filter.css";

function Filter(props) {
  const [filterClicked, setFilterClicked] = useState(false);
  const toggledFilter = () => {
    setFilterClicked(!filterClicked);
  };

  return (
    <div className="filtro-daterangepicker">
      <div
        className={`filtro-daterangepicker-icon ${
          filterClicked ? "filtro-daterangepicker-background" : ""
        }`}
      >
        <div className="datepicker">
          {filterClicked && (
            <>
              <DateFilter handleDatePick={props.handleDatePick} />
              <Option />
            </>
          )}
        </div>
        <div className="filter-icon">
          <FunnelIcon
            style={{ fontSize: "1.2em", marginRight: 10 }}
            onClick={toggledFilter}
          />
        </div>
      </div>
    </div>
  );
}

export default Filter;
