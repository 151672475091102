import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema, yupIsLogin } from "../../utils/schema";

import Button from "../../components/UI/Button";

import imagem from "../../assets/contactus.png";

import axios from "../../services/axios";

import "./Contact.css";
import { BeatLoader } from "react-spinners";

function Contact() {
  yupIsLogin("contactus");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isValid },
    setError,
    reset,
  } = useForm({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const submitHandle = async (userData) => {
    setIsSubmitting(true);
    const url = "/v1/send-contact";

    try {
      await axios.post(url, userData);
      setIsSubmitting(false);
      setSuccessMsg("Mensagem enviada com sucesso!");
      reset();
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
      let errorMessage = `Falha no envio da mensagem. Favor tentar novamente`;
      setError("apiError", { message: errorMessage });
    }
  };

  return (
    <div className="contact-seawire-contact screen">
      <div className="overlap-group1">
        <div className="image-placeholder">
          <img className="image-custom" src={imagem} alt="celular com dados" />
        </div>

        <form className="contact-us">
          <h1 className="title">Fale Conosco</h1>
          <div className="first-name-1">
            <label className="first-name roboto-normal-blueberry-16px">
              Nome
            </label>
            <div className="overlap-group-custom">
              <input
                className="first-name input-custom roboto-normal-gray-chateau-16px"
                placeholder="Digite seu nome"
                type={"text"}
                name="nameame"
                autoComplete="username"
                {...register("name", {
                  required: true,
                  message: "Campo nome obrigatório",
                })}
              />
            </div>
            <span className="error-message">
              {touchedFields.name && errors.name?.message}
            </span>
          </div>
          <div className={`contact-us-item-1`}>
            <label className="first-name roboto-normal-blueberry-16px">
              Sobrenome
            </label>
            <div className="overlap-group-custom">
              <input
                className="first-name input-custom roboto-normal-gray-chateau-16px"
                placeholder="Digite seu sobrenome"
                type={"text"}
                name="lastName"
                autoComplete="username"
                {...register("lastName", {
                  required: true,
                  message: "Campo sobrenome obrigatório",
                })}
              />
            </div>
            <span className="error-message">
              {touchedFields.lastName && errors.lastName?.message}
            </span>
          </div>
          <div className={`contact-us-item-1 contact-us-item`}>
            <label className="first-name roboto-normal-blueberry-16px">
              E-mail
            </label>
            <div className="overlap-group-custom">
              <input
                className="first-name input-custom roboto-normal-gray-chateau-16px"
                placeholder="Digite seu e-mail"
                type={"text"}
                name="email"
                autoComplete="username"
                {...register("email", {
                  required: true,
                  message: "Campo email obrigatório",
                })}
              />
            </div>
            <span className="error-message">
              {touchedFields.email && errors.email?.message}
            </span>
          </div>
          <div className={`contact-us-item-1 message`}>
            <label className="first-name roboto-normal-blueberry-16px">
              Mensagem
            </label>
            <div className="overlap-group-custom overlap-group-1">
              <input
                className="first-name input-custom roboto-normal-gray-chateau-16px"
                placeholder="Digite sua mensagem"
                type={"text"}
                name="msg"
                {...register("message", {
                  required: true,
                  message: "Campo mensagem obrigatório",
                })}
              />
            </div>
            <span className="error-message">
              {touchedFields.message && errors.message?.message}
            </span>
          </div>
          {errors.apiError && (
            <p className="msg-error">{errors.apiError?.message}</p>
          )}
          <div className="succsess-button">
            <Button
              class={"contactus-button contactus-button-color"}
              disabled={isSubmitting || !isValid}
              onSubmit={handleSubmit(submitHandle)}
            >
              {isSubmitting ? (
                <BeatLoader color={"var(--background)"} size={12} />
              ) : (
                "Enviar"
              )}
            </Button>
            {successMsg && <p className="msg-success">{successMsg}</p>}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
