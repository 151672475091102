import React, { useState, useContext, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Logo from "../../assets/logo.jpeg";
import { useMediaQuery } from "react-responsive";
import { Link, useHistory } from "react-router-dom";
import AuthContext from "../../store/auth-context";

import "./Navigation.css";

function Navigation() {
  const [selected, setSelected] = useState("");
  const handleLinkClick = (link) => {
    setSelected(link);
  };
  useEffect(() => {
    if (window.location.pathname === "/estabelecimentos") setSelected("estab");
    else if (window.location.pathname === "/produtos") setSelected("prod");
    else if (window.location.pathname === "/ranking") setSelected("ranking");
    else if (window.location.pathname === "/user") setSelected(null);
  }, []);

  const history = useHistory();
  const authCtx = useContext(AuthContext);
  const handleClick = (eventkey) => {
    if (eventkey === "2" && authCtx.isLoggedIn) {
      authCtx.logout();
      history.replace("/");
    }

    //TODO: incluir && authCtx.isLoggedIn

    if (eventkey === "1") {
      setSelected(null);
      history.replace("/user");
    }
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="/"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="user-active"
    >
      {children}
    </a>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const value = "";

      return (
        <div
          ref={ref}
          style={(style, { inset: "64px -30px auto auto" })}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const isMobileP = useMediaQuery({
    query: "(max-width: 27em)",
  });

  return (
    <header className="header">
      <div className="header-logo">
        <Link to="/ranking" onClick={() => handleLinkClick("ranking")}>
          <img className="logo" src={Logo} alt="logo site" />
        </Link>
      </div>
      <nav>
        {!isMobileP && (
          <ul className="menu">
            <li>
              <Link
                to="/ranking"
                className={`gray-bold hover ${
                  selected === "ranking" ? "selected" : ""
                }`}
                onClick={() => handleLinkClick("ranking")}
              >
                <div>Ranking</div>
              </Link>
            </li>
            <li>
              <Link
                to="/estabelecimentos"
                className={`gray-bold hover ${
                  selected === "estab" ? "selected" : ""
                }`}
                onClick={() => handleLinkClick("estab")}
              >
                <div>Estabelecimentos</div>
              </Link>
            </li>
            <li>
              <Link
                to="/produtos"
                className={`gray-bold hover ${
                  selected === "prod" ? "selected" : ""
                }`}
                onClick={() => handleLinkClick("prod")}
              >
                <div>Produtos</div>
              </Link>
            </li>
            <li>
              <svg width="2" height="36" viewBox="0 0 2 36" fill="none">
                <line
                  x1="1.16187"
                  y1="35.4976"
                  x2="1.33082"
                  y2="0.497583"
                  stroke="#DBE9F5"
                />
              </svg>
            </li>
            <li>
              <Dropdown onSelect={handleClick}>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  <div className="usuario">
                    <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
                      <path
                        d="M12.7303 11.7812C11.6415 11.7812 11.1179 12.375 9.33107 12.375C7.5442 12.375 7.02445 11.7812 5.93184 11.7812C3.11686 11.7812 0.833008 14.0152 0.833008 16.7687V17.7188C0.833008 18.7021 1.64867 19.5 2.65402 19.5H16.0081C17.0135 19.5 17.8291 18.7021 17.8291 17.7188V16.7687C17.8291 14.0152 15.5453 11.7812 12.7303 11.7812ZM16.0081 17.7188H2.65402V16.7687C2.65402 15.0023 4.12601 13.5625 5.93184 13.5625C6.48573 13.5625 7.38486 14.1562 9.33107 14.1562C11.2924 14.1562 12.1726 13.5625 12.7303 13.5625C14.5361 13.5625 16.0081 15.0023 16.0081 16.7687V17.7188ZM9.33107 11.1875C12.3471 11.1875 14.7941 8.79395 14.7941 5.84375C14.7941 2.89355 12.3471 0.5 9.33107 0.5C6.31501 0.5 3.86803 2.89355 3.86803 5.84375C3.86803 8.79395 6.31501 11.1875 9.33107 11.1875ZM9.33107 2.28125C11.338 2.28125 12.9731 3.88066 12.9731 5.84375C12.9731 7.80684 11.338 9.40625 9.33107 9.40625C7.32416 9.40625 5.68904 7.80684 5.68904 5.84375C5.68904 3.88066 7.32416 2.28125 9.33107 2.28125Z"
                        fill="#8498AE"
                      />
                    </svg>
                    <svg width="15" height="6" viewBox="0 0 15 8" fill="none">
                      <path
                        d="M1.83081 1L7.91532 7L13.9998 1"
                        stroke="#8498AE"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  <Dropdown.Item eventKey="1">Área do Usuário</Dropdown.Item>
                  <Dropdown.Item eventKey="2">Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        )}
      </nav>
    </header>
  );
}
export default Navigation;
