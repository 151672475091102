import React from "react";

function OpenModalButton(props) {
  return (
    <div className="frame-2-1">
      {props.brasil && (
        <div className="overlap-group">
          <button className="rectangle-1" onClick={props.openModal}>
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none">
              <path
                d="M13.1042 7.89583L19.875 1.125M19.875 1.125H13.625M19.875 1.125V7.375M1.125 19.875L7.89583 13.1042M1.125 19.875V13.625M1.125 19.875H7.375"
                stroke="var(--primary)"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      )}
    </div>
  );
}

export default OpenModalButton;
