import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../store/auth-context";
import GeneralContainer from "../../components/UI/GeneralContainer";
import Title from "../../components/UI/Title";
import api from "../../services/axios";
import { withRouter } from "react-router-dom";
import { disclaimerRanking } from "../../utils/disclaimers";
import ChartBox from "../../components/ChartBox";
import SkeletonCustom from "../../components/UI/SkeletonCustom";
import { listEndpointsRanking } from "../../utils/listEndpoints";
import "./Ranking.css";

function Ranking() {
  const authCtx = useContext(AuthContext);
  const [response, setResponse] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const accToken = authCtx.accToken;

    async function fetchDataCharts(endpoints) {
      let listaEndpoints = [];

      for (const endpoint in endpoints) {
        try {
          setIsloading(true);
          const resp = await api.get(`/v1/get-${endpoints[endpoint]}`, {
            headers: { Authorization: `Bearer ${accToken}` },
          });
          listaEndpoints.push(resp);

          setResponse((response) => [...response, resp.data]);
        } catch (error) {
          console.log(error);
        }
      }
      setIsloading(false);
      return true;
    }

    fetchDataCharts(listEndpointsRanking);
  }, [authCtx.accToken]);

  const title = {
    title: "Ranking",
    atualizacao: true,
    filter: false,
  };

  return (
    <GeneralContainer>
      <section className="main">
        <div className="main-scroll">
          <Title {...title} />
          {isLoading ? (
            <div className="main-scroll-item">
              <SkeletonCustom />
              <SkeletonCustom />
              <SkeletonCustom />
              <SkeletonCustom />
            </div>
          ) : (
            <>
              <div className="main-scroll-item">
                {response.map((resp, key) => {
                  return (
                    <ChartBox
                      id={key}
                      key={key}
                      {...resp}
                      disclaimer={disclaimerRanking[key]}
                      noOptions
                      ranking
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </section>
    </GeneralContainer>
  );
}

export default withRouter(Ranking);
