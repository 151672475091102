import React from "react";
import { withRouter } from "react-router-dom";
import Logo from "../../assets/logo2.png";
import AuthForm from "../../Auth";

import "./AuthPage.css";

function AuthPage(props) {
  return (
    <div className="container-cadastro perspective-container">
      <div className="container-logo perspective-element">
        <div className="div-logo">
          <img className="logo-cadastro" src={Logo} alt="logo" />
        </div>
      </div>
      <AuthForm {...props} />
    </div>
  );
}

export default withRouter(AuthPage);
