import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { useMedia } from "react-use";

export default function SkeletonCustom() {
  const isphone = useMedia("(max-width: 55em)");

  const skeleton = {
    height: isphone ? "137px" : "321px",
    cardHeight: isphone ? "67px" : "72px",
  };

  return (
    <>
      <Card>
        <CardHeader
          sx={{ height: skeleton.cardHeight }}
          title={
            <Skeleton
              animation="wave"
              height={10}
              width="80%"
              style={{ marginBottom: 6 }}
            />
          }
          subheader={
            <Skeleton
              animation="wave"
              height={10}
              width="40%"
              style={{ marginBottom: 6 }}
            />
          }
        />
        <Skeleton variant="rounded" height={skeleton.height} />
      </Card>
    </>
  );
}
