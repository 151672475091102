import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Chart from "../Chart";
import SvgBrasil from "../UI/SvgBrasil";
import OpenModalButton from "../UI/OpenModalButton";
import { useMedia } from "react-use";

import "./ChartBox.css";

function ChartBox(props) {
  const isMobile = useMedia("(max-width: 70em)");

  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[6],
      fontSize: "var(--font-size-m)",
      fontFamily: "var(--font-family-roboto)",
      padding: 10,
    },
  }));

  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <div className="ativos">
      <div className="s-ativos">
        <div className="graph">
          <div className={props.ranking ? "header-2-ranking" : "header-2"}>
            <div className="frame-1">
              <h1 className="estabelecimentos-ativos x18px-bold">
                {props.title}
              </h1>
              {!props.svgType && !isMobile ? (
                <CustomTooltip title={props.disclaimer} placement="bottom">
                  <div className="header-disclaimer">
                    <FontAwesomeIcon
                      id={props.id}
                      icon={["fas", "circle-question"]}
                      className={"disclaimer-icon"}
                    />
                  </div>
                </CustomTooltip>
              ) : (
                <div className="header-disclaimer">
                  <Grid container>
                    <Grid item>
                      <ClickAwayListener onClickAway={handleTooltipClose}>
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          onClose={handleTooltipClose}
                          open={open}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          title={props.disclaimer}
                          placement="bottom"
                          classes={{
                            popper: "popper-custom",
                            tooltip: "tooltip-custom",
                          }}
                        >
                          <FontAwesomeIcon
                            id={props.id}
                            icon={["fas", "circle-question"]}
                            className={"disclaimer-icon"}
                            onClick={handleTooltipOpen}
                          />
                        </Tooltip>
                      </ClickAwayListener>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>

            {props.noOptions ? null : (
              <OpenModalButton
                openModal={props.openModal}
                brasil={!props.brasil && true}
              />
            )}
          </div>
          {props.svgType ? (
            <div className="img-chart">
              <SvgBrasil svg={props.svgBrasil} datePick={props.datePick} />
            </div>
          ) : (
            <>
              <Chart {...props} />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default ChartBox;
