import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";
import AdmZip from "adm-zip";
import AuthContext from "../../store/auth-context";
import Busca from "../../components/UI/Busca";
import Chart from "../../components/Chart";
import ChartBox from "../../components/ChartBox";
import GeneralContainer from "../../components/UI/GeneralContainer";
import Modal from "../../components/UI/Modal";
import SkeletonCustom from "../../components/UI/SkeletonCustom";
import Title from "../../components/UI/Title";
import axios from "axios";
import api from "../../services/axios";
import parse from "html-react-parser";
import { useLocation } from "react-router-dom";
import { disclaimerEstab, disclaimerProdutos } from "../../utils/disclaimers";
import { listEndpoints, listEndpointsNcm } from "../../utils/listEndpoints";
import "./Main.css";

function Main() {
  const location = useLocation();
  const [searchFromRanking, setSearchFromRanking] = useState(location.state);

  const pathnameEstab = window.location.pathname === "/estabelecimentos";
  const authCtx = useContext(AuthContext);
  const accToken = authCtx.accToken;

  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [scrollRef]);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showGraph, setShowGraph] = useState(false);
  const [cnaeSetter, setCnaeSetter] = useState([]);
  const [ncmSetter, setNcmSetter] = useState([]);
  const [cnaeOrNcm, setCnaeOrNcm] = useState();
  const [svgBrasil, setSvgBrasil] = useState();
  const [openModal, setOpenModal] = useState({
    title: "",
    chartInfos: [],
    bool: false,
    brasil: false,
  });

  const [listaCnaes, setListaCnaes] = useState([]);
  const [listaNcm, setListaNcm] = useState([]);

  useEffect(() => {
    if (selectedOptions === null || selectedOptions.length === 0) {
      setShowGraph(false);
    }
  }, [showGraph, selectedOptions]);

  useEffect(() => {
    setCnaeOrNcm(pathnameEstab ? cnaeSetter : ncmSetter);
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
  }, [cnaeSetter, ncmSetter, pathnameEstab]);

  const mapLabels = (data) => {
    const code = data.CODIGO;
    const description = data.DESCRICAO;
    return {
      value: code,
      label: `${code} - ${description}`,
    };
  };

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const loadOptions = async (search, prevOptions) => {
    await sleep(1000);

    const url = pathnameEstab ? `/v1/get-cnaes` : `/v1/get-all-agg-ncms`;
    const response = await api.get(url);
    const responseJSON = await response.data;
    const mapJSON = responseJSON.map(mapLabels);

    let filteredOptions;
    if (!search) {
      filteredOptions = mapJSON;
    } else {
      if (parseInt(search)) {
        filteredOptions = mapJSON.filter(({ label }) => label.includes(search));
      } else {
        filteredOptions = mapJSON.filter(({ label }) =>
          label.toLowerCase().includes(search)
        );
      }
      // else if (search.length >= 6) {
      //   console.log("aqui");
      //   const searchLower = search.toLowerCase();
      //   const object = { texto: searchLower };
      //   const urlSearch = pathnameEstab
      //     ? "/v1/search-cnaes"
      //     : "/v1/search-ncms";
      //   const response = await api.post(urlSearch, object);
      //   filteredOptions = response.data.map(mapLabels);
      // }
    }

    const hasMore = filteredOptions.length > prevOptions.length + 100;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 100
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition);
    }
  };

  const fetchDataCharts = useCallback(
    async (endpoints) => {
      const listEndpoint = pathnameEstab ? listaCnaes : listaNcm;
      let listaEndpoints = [];
      for (const endpoint in endpoints) {
        try {
          setIsLoading(true);
          const resp = await api.post(
            `/v1/get-${endpoints[endpoint]}`,
            listEndpoint,
            {
              headers: { Authorization: `Bearer ${accToken}` },
            }
          );

          if (endpoints[endpoint] === "active-stores-per-city-zip") {
            const b = Buffer.from(resp.data.body, "base64");
            const zip = new AdmZip(b);
            const zipEntries = zip.getEntries();

            let svg = [];
            for (const zipEntry of zipEntries) {
              svg.push(zipEntry.getData().toString("utf8"));
            }
            const svgChaves = <>{parse(svg[0])}</>;

            setSvgBrasil(svgChaves);
          } else {
            listaEndpoints.push(resp);
          }
        } catch (error) {
          console.log(error);
        }
      }

      try {
        await axios.all(listaEndpoints).then(
          axios.spread((...allData) => {
            setIsLoading(false);
            setCnaeSetter([]);
            setNcmSetter([]);
            setShowGraph(true);
            allData.map((data) => {
              let aux = "";
              listEndpoint.map((element) => {
                const el = pathnameEstab ? element.cnae : element.ncm;
                if (listEndpoint.length > 1) {
                  return (aux += `${el}|`);
                } else {
                  handleScrollPosition();
                  if (pathnameEstab) {
                    return setCnaeSetter((cnaeSetter) => [
                      ...cnaeSetter,
                      data.data[el],
                    ]);
                  } else {
                    return setNcmSetter((ncmSetter) => [
                      ...ncmSetter,
                      data.data[el],
                    ]);
                  }
                }
              });
              //more than one cnae/ncm
              if (aux !== "") {
                handleScrollPosition();
                aux = aux.slice(0, aux.length - 1);
                if (pathnameEstab) {
                  return setCnaeSetter((cnaeSetter) => [
                    ...cnaeSetter,
                    data.data[aux],
                  ]);
                } else {
                  return setNcmSetter((ncmSetter) => [
                    ...ncmSetter,
                    data.data[aux],
                  ]);
                }
              }
              return null;
            });
          })
        );
      } catch (err) {
        console.error("ops! ocorreu um erro" + err);
        setIsLoading(false);
        setShowGraph(false);
      }
      return true;
    },
    [accToken, listaCnaes, listaNcm, pathnameEstab]
  );

  const handleSelect = useCallback(
    async (data) => {
      if (pathnameEstab) {
        setSelectedOptions(data ? data : null);
        setCnaeSetter([]);
        setListaCnaes([]);
        setShowGraph(false);

        if (data) {
          if (searchFromRanking) {
            setSelectedOptions([data]);
            [data].forEach((element) => {
              listaCnaes.push({ cnae: element.value });
            });
            setSearchFromRanking(false);
          } else {
            data.forEach((element) => {
              listaCnaes.push({ cnae: element.value });
            });
          }
        }
        if (listaCnaes.length > 0) {
          await fetchDataCharts(listEndpoints);
        }
      } else if (window.location.pathname === "/produtos") {
        setSelectedOptions(data ? data : null);
        setNcmSetter([]);
        setListaNcm([]);
        if (searchFromRanking) {
          setSelectedOptions([data]);
          [data].forEach((element) => {
            listaNcm.push({ ncm: element.value });
          });
          setSearchFromRanking(false);
        } else {
          data.forEach((element) => {
            listaNcm.push({ ncm: element.value });
          });
        }
        if (listaNcm.length > 0) {
          await fetchDataCharts(listEndpointsNcm);
        }
      }
    },
    [
      fetchDataCharts,
      listEndpoints,
      listEndpointsNcm,
      listaCnaes,
      listaNcm,
      pathnameEstab,
      searchFromRanking,
    ]
  );

  useEffect(() => {
    if (searchFromRanking) {
      handleSelect(searchFromRanking);
    }
  }, [searchFromRanking, handleSelect]);

  function handleClose() {
    setOpenModal(false);
  }

  function handleOpen(title, chartInfos, brasil) {
    setOpenModal({
      title: title,
      chartInfos: chartInfos,
      bool: true,
      brasil: brasil,
    });
  }

  const [datePick, setDatePick] = useState();

  const handleDatePick = (date) => {
    if (date) {
      const firtDate = date[0];
      const lastDate = date[1];
      setDatePick([firtDate, lastDate]);
    } else {
      setDatePick(null);
    }
  };

  useEffect(() => {
    if (datePick && selectedOptions && selectedOptions.length > 0) {
      handleSelect(selectedOptions);
      fetchDataCharts(pathnameEstab ? listEndpoints : listEndpointsNcm);
    } else if (datePick === null) {
      handleSelect(selectedOptions);
      fetchDataCharts(pathnameEstab ? listEndpoints : listEndpointsNcm);
    }
  }, [datePick]);

  const modalData = {
    show: openModal.bool,
    title: openModal.title,
    close: handleClose,
    chartInfos: openModal.chartInfos,
    brasil: openModal.brasil,
  };

  const title = {
    title: pathnameEstab ? "Estabelecimentos" : "Produtos",
    atualizacao: true,
  };

  const busca = {
    loadOptions,
    handleSelect,
    pathnameEstab,
    searchFromRanking,
    filter: true,
    handleDatePick,
  };

  return (
    <GeneralContainer>
      <div className="main">
        <div className="main-scroll" ref={scrollRef}>
          <Title {...title} />
          <Busca {...busca} />
          {selectedOptions.length > 0 && (
            <div className="main-scroll-item">
              {isLoading && (
                <>
                  <SkeletonCustom />
                  <SkeletonCustom />
                  <SkeletonCustom />
                  <SkeletonCustom />
                </>
              )}
              {cnaeOrNcm.map((item, key) => {
                return (
                  <ChartBox
                    id={key}
                    key={key}
                    title={item.title}
                    openModal={() => handleOpen(item.title, item.chartInfos)}
                    chartInfos={item.chartInfos}
                    datePick={datePick}
                    disclaimer={
                      pathnameEstab
                        ? disclaimerEstab[key]
                        : disclaimerProdutos[key]
                    }
                  />
                );
              })}
            </div>
          )}
          {showGraph && pathnameEstab && (
            <ChartBox
              title={"Estabelecimentos no Brasil"}
              svgBrasil={svgBrasil}
              svgType
              noOptions
              datePick={datePick}
            />
          )}
        </div>
        <Modal {...modalData}>
          <h1>{modalData.title}</h1>
          <Chart {...modalData} style />
        </Modal>
      </div>
    </GeneralContainer>
  );
}
export default Main;
