import React, { useContext } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import AuthPage from "./pages/AuthPage";
import AuthContext from "./store/auth-context";
import LandingPage from "./pages/LandingPage";
import Ranking from "./pages/Ranking";
import Main from "./pages/Main";
import User from "./pages/User";
import Contact from "./pages/Contact";

function App() {
  const authCtx = useContext(AuthContext);

  return (
    <Router>
      <Switch>
        <Route path="/" exact component={() => <LandingPage />} />
        <Route path="/contact" exact component={() => <Contact />} />
        {/* <Route path="/register" component={() => <AuthPage register />} /> */}
        {!authCtx.isLoggedIn && (
          <Route path="/login" component={() => <AuthPage login />} />
        )}
        <Route path="/forgot-password" component={() => <AuthPage forgot />} />

        {authCtx.isLoggedIn && (
          <Switch>
            <Route path="/estabelecimentos" component={() => <Main />} />
            <Route path="/produtos" component={() => <Main />} />
            <Route path="/ranking" component={() => <Ranking />} />
            <Route path="/user" component={() => <User />} />
            <Redirect to={authCtx.isLoggedIn ? "/ranking" : "/"} />
          </Switch>
        )}
        <Redirect to={authCtx.isLoggedIn ? "/ranking" : "/"} />
      </Switch>
    </Router>
  );
}

export default App;
